import * as React from "react"

import PressIndex from './PressIndex'

const HeroSection = ({ data }) => {
    return (
        <div className="background1">
            <div className="md:pt-40 pt-32 lg:pb-32 pb-20 relative ">
                <div className="bg1 absolute top-0 right-0"></div>
                <div className="max-w-4xl mx-auto px-4 ">
                    <div className="md:text-center text-left abt-txt">
                        <div className="text-lg md:mb-8 mb-3 font-medium text-blue">PRESS</div>
                        <h1 className="md:max-w-xl md:mx-auto md:w-full w-52 lg:text-7xl md:text-6xl text-3xl md:mb-9 mb-5 text-blue font-medium tracking-tighter">What’s been said about us</h1>
                        <div className="relative">
                            <div className="press-bg1 absolute md:right-24 sm:right-32 right-16 md:-top-6 sm:-bottom-36 -bottom-28"></div>
                            <div className="press-bg2 z-10 absolute md:right-0 sm:right-16 right-4 md:-top-24 sm:-bottom-16 -bottom-10"></div>
                        </div>
                        {/* <div className="flex gap-2 justify-center items-start md:items-center md:flex-row flex-col">
                            <ButtonGetStarted />
                            <ButtonWatchDemo />
                        </div> */}
                    </div>
                </div>
                <div className="mx-auto px-4 sm:pt-28 pt-20">
                    <PressIndex data={data} />
                    {/* <FeaturesList items={items} /> */}
                </div>
            </div>
        </div>
    )
}
export default HeroSection
