import React, { useState, useEffect } from "react"
import PressItem from './PressItem'


const PressIndex = ({ data }) => {

    const allPosts = data
    const showRecords = 6
    const [list, setList] = useState([...allPosts.slice(0, showRecords)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(allPosts.length > showRecords)

    const handleLoadMore = () => {
        setLoadMore(true)
    }
    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < allPosts.length
            const nextResults = isMore
                ? allPosts.slice(currentLength, currentLength + showRecords)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore])

    useEffect(() => {
        const isMore = list.length < allPosts.length
        setHasMore(isMore)
    }, [list])
    return (
        <div className="max-w-7xl mx-auto px-4 mb-8">
            <div className="relative border-t border-red mt-20">
                {list.map((article) => (
                    <PressItem key={article.id} data={article} />
                ))}
            </div>
            <div className="flex items-center justify-center md:mt-20 mt-10">
                {hasMore ? (
                    <button onClick={handleLoadMore} className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block btn1">Load More</button>
                ) : (
                    <p className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 mb-0 tracking-wide uppercase inline-block btn1">No more results</p>
                )}
            </div>
        </div>
    )
}
export default PressIndex       