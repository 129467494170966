import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"

import HeroSection from '../components/Press/HeroSection'
import Blogsection from '../components/Press/BlogSection';
const Press = (props) => {
  const { data } = props
  const {
    AllPress
  } = data;


  if (!AllPress) return null;
  return (
    <Layout>
      <SEO
        context={{
          ...props.pageContext,
          title: 'Carriyo | Press Articles',
        }}
        cpath={props.location.pathname}
      />
      <HeroSection data={AllPress.edges}/>
      <Blogsection/>
    </Layout>
  );
};



export default Press;

export const data = graphql`
  query {
    AllPress : allPrismicPress(
      sort: { fields: last_publication_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            title {
              text
              html
            }
            short_description
            image {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            source
            source_link
          }
        }
      }
    }
  }
`;
