import * as React from "react"
import moment from 'moment';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../Elements/Link";

const BlogItem = ({data}) => {
  const { title, short_description, image, source, source_link} = data.node.data
  const publish_date = data.node.last_publication_date
  return (

    <div className="flex lg:flex-row flex-col items-center border-b lg:py-20 py-10 border-blue-900">
      <div className="lg:w-8/12 w-full 2xl:pr-32">               
        <div>
          <h2 className="text-blue mb-3 text-lg font-bold tracking-tight">{source && source}</h2>
          <h3 className="text-blue font-bold md:text-4xl text-2xl mb-5">
            <Link href={source_link} className="text-blue font-bold md:text-4xl text-2xl no-underline">
              {title?.text}
            </Link>
          </h3>
          <p className="text-blue text-lg font-normal ">{short_description && short_description}</p>
          <Link href={source_link} className="lg:hidden inline-block mb-8 uppercase bg-blue text-white rounded-full py-2 px-10 no-underline">
            Read
          </Link>
          <div className="lg:flex gap-16 flex-col items-start hidden">
            <p className="text-gray-400 font-normal text-base m-0 ">Posted on {moment(publish_date).format('MMM D, YYYY')}</p>
            <Link href={source_link} className="bg-blue text-white uppercase rounded-full py-2 px-10 no-underline">
              Read
            </Link>
          </div>
        </div>
      </div>
      <div className="lg:w-4/12 w-full">
        <Link href={source_link}>
          <GatsbyImage image={getImage(image)} alt={title && title.text} className="w-full rounded-2xl" />
        </Link>
        <p className="text-gray-400 font-normal text-base m-0 lg:hidden block mt-4">Posted on {moment(publish_date).format('MMM D, YYYY')}</p>
      </div>
    </div>
  )
}
export default BlogItem       
